import  { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import {
  faComments,
  faFileAlt,
  faCloud,
  faUserCheck,
  faPlug,
  faServer,
  faLayerGroup,
  faBrowser,
  faGlobe,
  faProjectDiagram
} from '@fortawesome/pro-light-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

export const onClientEntry = () => {
  library.add(
    faTwitter,
    faInstagram,
    faLinkedin,
    faGithub,
    faComments,
    faFileAlt,
    faCloud,
    faUserCheck,
    faPlug,
    faServer,
    faLayerGroup,
    faCircle,
    faBrowser,
    faGlobe,
    faProjectDiagram
  )
}
